import React from "react"
import PropTypes from "prop-types"
import GatsbyImage from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

// dynamically decide whether to use Gatsby-Image or a normal Img as prismic previews can't access localFile as usePrismicPreview() returns just the url field for an image.
// for more details see:
// https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews-api.md#using-locally-transformed-images
//
// This is left as a useful feature, that can be used, if localFile should be used again. For the moment images are handled using imgix integration through prismic. More details: https://github.com/angeloashmore/gatsby-source-prismic#Image-processing

const DynamicImage = ({ alt, src, fixed, fluid, ...props }) => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicSiteSettings {
        data {
          default_main_image {
            alt
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fluid(maxWidth: 900) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  `)
  // Fallback if no image defined
  if (!src && !fluid && !fixed) {
    return staticData.prismicSiteSettings.data.default_main_image.url &&
      !staticData.prismicSiteSettings.data.default_main_image.localFile
        ?.childImageSharp?.fluid ? (
      <img
        alt={staticData.prismicSiteSettings.data.default_main_image.alt}
        src={staticData.prismicSiteSettings.data.default_main_image.url}
        {...props}
        style={props.imgStyle}
      />
    ) : (
      <GatsbyImage
        alt={staticData.prismicSiteSettings.data.default_main_image.alt}
        fluid={
          staticData.prismicSiteSettings.data.default_main_image.localFile
            ?.childImageSharp?.fluid
        }
        {...props}
      />
    )
  }

  return src && !fluid ? (
    <img alt={alt} src={src} {...props} style={props.imgStyle} />
  ) : (
    <GatsbyImage alt={alt} fluid={fluid} fixed={fixed} {...props} />
  )
}

export default DynamicImage

DynamicImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  fixed: PropTypes.object,
  fluid: PropTypes.object,
}
